import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";
import CarImg1 from "../images/cars-big/audia1.png";
import CarImg2 from "../images/cars-big/bmw320.png";
import CarImg3 from "../images/cars-big/golf6.png";
import CarImg4 from "../images/cars-big/audia1.jpg";
import CarImg5 from "../images/cars-big/passatcc.png";
// import CarImg6 from "../images/cars-big/passat-box.png";
// import { Link } from "react-router-dom";
import SectionWithImage from "./SectionWithImage";

function Models() {
  return (
    <>
      <section className="models-section">
        <HeroPages name="Services" />
        <div>
          <SectionWithImage
            imageSrc={CarImg1}
            heading="Car Rental Services in Gurgaon"
            text="Explore Gurgaon with ease and comfort through our comprehensive car rental services. At Sainik Tour And Travels, we offer a wide range of vehicles to suit your needs, whether you're traveling for business or leisure. Our fleet includes everything from compact cars to spacious SUVs, ensuring that you find the perfect vehicle for your journey in Gurgaon"
          />
          <SectionWithImage
            imageSrc={CarImg2}
            heading="Corporate Car Rental Solutions in Gurgaon"
            text="Experience seamless corporate travel with our tailored car rental solutions in Gurgaon. Sainik Tour And Travels specializes in providing reliable and professional transportation services for corporate clients. With our well-maintained vehicles and experienced drivers, you can focus on your business while we take care of your travel needs in Gurgaon and beyond."
          />
          <SectionWithImage
            imageSrc={CarImg3}
            heading="Outstation Car Rental Services from Gurgaon"
            text="Embark on unforgettable adventures with our outstation car rental services from Gurgaon. Sainik Tour And Travels provides convenient and reliable transportation for your outstation trips, allowing you to explore nearby destinations with comfort and convenience. With our well-maintained vehicles and experienced drivers, you can enjoy a hassle-free travel experience from Gurgaon."
          />
          <SectionWithImage
            imageSrc={CarImg4}
            heading="Luxury Car Rentals for Gurgaon Travel"
            text="ndulge in luxury and sophistication with our premium car rental services in Gurgaon. Sainik Tour And Travels offers a fleet of luxury vehicles that elevate your travel experience. Whether you're attending a special event or exploring the city in style, our luxury car rentals ensure a memorable journey through the streets of Gurgaon."
          />
          <SectionWithImage
            imageSrc={CarImg5}
            heading="Explore Gurgaon with Tempo Bus Rentals"
            text="Discover Gurgaon and its surrounding areas with our spacious tempo bus rentals. Sainik Tour And Travels offers comfortable and convenient transportation solutions for group travel in Gurgaon. Whether you're planning a corporate outing, a family trip, or a group excursion, our tempo bus rentals ensure a smooth and enjoyable journey for everyone."
          />
        </div>
        <div className="book-banner">
          <div className="book-banner__overlay"></div>
          <div className="container">
            <div className="text-content">
              <h2>Book a car by getting in touch with us</h2>
              <span>
                <i className="fa-solid fa-phone"></i>
                <h3>(+91) 9910997458</h3>
              </span>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
}

export default Models;
