import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";
import PlanTrip from "../components/PlanTrip";
import AboutMain from "../images/about/about-main.jpg";
// import Box1 from "../images/about/icon1.png";
// import Box2 from "../images/about/icon2.png";
// import Box3 from "../images/about/icon3.png";

function About() {
  return (
    <>
      <section className="about-page">
        <HeroPages name="About" />
        <div className="container">
          <div className="about-main">
            <img
              className="about-main__img"
              src={AboutMain}
              alt="car-renting"
            />
            <div className="about-main__text">
              <section>
                <h3>Premier Quality Service</h3>
                <p>Embark on a journey of unparalleled comfort and satisfaction. Our Director, Mr. Devendra Singh, leads with a vision to deliver premier quality service, placing customer satisfaction at the forefront with our "Customer First" policy.</p>
              </section>
              <section>
                <h3>GPS Enabled Fleet</h3>
                <p>Your safety is our priority. All our vehicles are equipped with GPS technology, allowing real-time tracking for added security and peace of mind.</p>
              </section>
              <section>
                <h3>24/7 Customer Support</h3>
                <p>We go beyond the road. Our state-of-the-art run back room operates 24/7, ensuring seamless coordination with our clients. Your needs are our priority, and our professional team is always ready to assist you.</p>
              </section>
              <section>
                <h3>Book Your Journey Today</h3>
                <p>Discover the difference with Sainik Tour & Travels. Book your ride now and let us redefine your travel experience.</p>
              </section>
              <section>
                <h3>Complimentary Amenities for Every Journey</h3>
                <p>In addition to our exceptional service, we also provide complimentary water bottles and carriers for outstation trips, ensuring your comfort throughout the ride.</p>
              </section>
            </div>
          </div>
          <PlanTrip />
        </div>
      </section>
      <div className="book-banner">
        <div className="book-banner__overlay"></div>
        <div className="container">
          <div className="text-content">
            <h2>Book a car by getting in touch with us</h2>
            <span>
              <i className="fa-solid fa-phone"></i>
              <h3>(+91) 9910997458</h3>
            </span>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default About;
