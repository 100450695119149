import { useState } from "react";

function Faq() {
  const [activeQ, setActiveQ] = useState("q1");

  const openQ = (id) => {
    setActiveQ(activeQ === id ? "" : id);
  };

  const getClassAnswer = (id) => {
    return activeQ === id ? "active-answer" : "";
  };

  const getClassQuestion = (id) => {
    return activeQ === id ? "active-question" : "";
  };

  return (
    <>
      <section className="faq-section">
        <div className="container">
          <div className="faq-content">
            <div className="faq-content__title">
              <h5>FAQ</h5>
              <h2>Frequently Asked Questions</h2>
              <p>
                Frequently Asked Questions About the Car Rental Booking Process
                on Our Website: Answers to Common Concerns and Inquiries.
              </p>
            </div>

            <div className="all-questions">
              <div className="faq-box">
                <div
                  id="q1"
                  onClick={() => openQ("q1")}
                  className={`faq-box__question  ${getClassQuestion("q1")}`}
                >
                  <p>1. How do I book a car rental on your website?</p>
                  <i className="fa-solid fa-angle-down"></i>
                </div>
                <div
                  id="q1"
                  onClick={() => openQ("q1")}
                  className={`faq-box__answer ${getClassAnswer("q1")}`}
                >
                  <h4>1. WhatsApp Reservation:</h4>
                  Click "Reserve" to start a WhatsApp chat.
                  Share your pickup/drop-off details and car preferences.
                  Our team will assist you promptly.

                  <h4>2. Direct Call:</h4>
                  Call us at <b>9910997458</b>. Our reps will guide you through booking and answer queries.
                  Hassle-free service guaranteed!
                </div>
              </div>
              <div className="faq-box">
                <div
                  id="q2"
                  onClick={() => openQ("q2")}
                  className={`faq-box__question ${getClassQuestion("q2")}`}
                >
                  <p>2. What types of cars are available for rent?</p>
                  <i className="fa-solid fa-angle-down"></i>
                </div>
                <div
                  id="q2"
                  onClick={() => openQ("q2")}
                  className={`faq-box__answer ${getClassAnswer("q2")}`}
                >
                  <p>
                  Discover a wide selection of rental vehicles to suit your needs, including&nbsp;
                  <b>Swift Dzire</b>,&nbsp;
                  <b>Toyota Innova Crysta</b>,&nbsp;
                  <b>Ertiga</b>,&nbsp; 
                  <b>BMW</b>,&nbsp;
                  <b>Tempo Traveller</b>,&nbsp; 
                  Looking for something specific or luxurious?
                  We also offer special requirement vehicles upon request.
                  From luxury models to custom features, we're committed to meeting your needs.
                  Contact us to book your preferred vehicle for a memorable rental experience.
                  </p>
                </div>
              </div>
              <div className="faq-box">
                <div
                  id="q3"
                  onClick={() => openQ("q3")}
                  className={`faq-box__question ${getClassQuestion("q3")}`}
                >
                  <p>3. How do I contact customer support if I have a question or issue?</p>
                  <i className="fa-solid fa-angle-down"></i>
                </div>
                <div
                  id="q3"
                  onClick={() => openQ("q3")}
                  className={`faq-box__answer ${getClassAnswer("q3")}`}
                >
                  You can contact our customer support team by phone, email, or live chat for
                  assistance with any questions or issues you may have before, during, or after your rental period.
                  prices.
                </div>
              </div>
              <div className="faq-box">
                <div
                  id="q4"
                  onClick={() => openQ("q4")}
                  className={`faq-box__question ${getClassQuestion("q4")}`}
                >
                  <p>4. Can I modify or cancel my reservation?</p>
                  <i className="fa-solid fa-angle-down"></i>
                </div>
                <div
                  id="q4"
                  onClick={() => openQ("q4")}
                  className={`faq-box__answer ${getClassAnswer("q4")}`}
                >
                  Yes, you can usually modify or cancel your reservation, although there may be fees associated with doing so,
                  especially if it's close to the pickup date.
                  Check the terms and conditions of your booking for specific details.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Faq;
