import { Link } from "react-router-dom";
import Logo from "../images/logo/TY.png";
import { useState } from "react";

function Navbar() {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const closeNav = () => {
    setModalOpen(false);
  };

  return (
    <nav>
      {/* desktop */}
      <div className="navbar desktop-navbar">
        <div className="navbar__img">
          <Link to="/" onClick={() => window.scrollTo(0, 0)}>
            <img src={Logo} alt="logo-img" />
          </Link>
        </div>
        <ul className="navbar__links">
          <li>
            <Link className="home-link" to="/" onClick={closeNav}>
              Home
            </Link>
          </li>
          <li>
            <Link className="about-link" to="/about" onClick={closeNav}>
              About
            </Link>
          </li>
          <li>
            <Link className="models-link" to="/models" onClick={closeNav}>
              Services
            </Link>
          </li>
          <li>
            <Link className="testi-link" to="/testimonials" onClick={closeNav}>
              Testimonials
            </Link>
          </li>
          <li>
            <Link className="team-link" to="/team" onClick={closeNav}>
              Our Team
            </Link>
          </li>
          <li>
            <Link className="contact-link" to="/contact" onClick={closeNav}>
              Contact
            </Link>
          </li>
        </ul>
      </div>

      {/* mobile */}
      <div className="navbar mobile-navbar">
        <div className="navbar__img">
          <Link to="/" onClick={() => window.scrollTo(0, 0)}>
            <img src={Logo} alt="logo-img" />
          </Link>
        </div>

        {/* mobile */}
        <div className="mobile-hamb" onClick={toggleModal}>
          <span>&#9776;</span> {/* Unicode character for menu icon */}
        </div>
      </div>

      {modalOpen && (
        <div className="mobile-modal" style={{ right: 0 }}>
          <div className="modal-content">
            <span className="close" onClick={toggleModal}>
              &times;
            </span>
            <ul className="modal-links" style={{ padding: "108px 0px 0px 0px", textDecoration: "none", fontSize: "25px", color: "#818181", display: "block", transition: "0.3s",textAlign: "center" }}>
              <li style={{listStyleType:'none'}}>
                <Link to="/" onClick={closeNav} style={{ textDecoration: 'none' }}>
                  Home
                </Link>
              </li>
              <li style={{listStyleType:'none'}}>
                <Link to="/about" onClick={closeNav} style={{ textDecoration: 'none' }}>
                  About
                </Link>
              </li>
              <li style={{listStyleType:'none'}}>
                <Link to="/models" onClick={closeNav} style={{ textDecoration: 'none' }}>
                  Services
                </Link>
              </li>
              <li style={{listStyleType:'none'}}>
                <Link to="/testimonials" onClick={closeNav} style={{ textDecoration: 'none' }}>
                  Testimonials
                </Link>
              </li>
              <li style={{listStyleType:'none'}} >
                <Link to="/team" onClick={closeNav} style={{ textDecoration: 'none' }}>
                  Our Team
                </Link>
              </li>
              <li style={{listStyleType:'none'}}>
                <Link to="/contact" onClick={closeNav} style={{ textDecoration: 'none' }}>
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
      )}
    </nav>
  );
}

export default Navbar;
