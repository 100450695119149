import MainImg from "../images/chooseUs/comb.jpeg";
import Box1 from "../images/chooseUs/icon1.png";
import Box2 from "../images/chooseUs/icon2.png";
import Box3 from "../images/chooseUs/icon3.png";
import Contact from "../images/plan/icon2.png";

function ChooseUs() {
  return (
    <>
      <section className="choose-section">
        <div className="container">
          <div className="choose-container">
            <img
              className="choose-container__img"
              src={MainImg}
              alt="car_img"
            />
            <div className="text-container">
              <div className="text-container__left">
                <h4>Why Choose Us</h4>
                <h2>Unlock Unbeatable Value and Deals - Best in Delhi NCR!</h2>
                <p>
                Embark on a journey of savings and satisfaction with Sainik Tour And Travels.
                 We're dedicated to exceptional value, providing top-quality services
                  and products without straining your budget. Explore exclusive deals
                   tailored to enhance your renting experience. Don't miss out on these
                    opportunities to save big and revel in luxury without compromise.
                </p>
                <h4>Join Us Today</h4>
                <p>
                Experience the ultimate blend of convenience, luxury, and affordability with
                 Sainik Tour And Travels. Don't settle for less when you can have it all.
                  Start your journey with us today and unlock unbeatable value!
                </p>
                <a href="/#/about">
                  Find Details &nbsp;
                  <i className="fa-solid fa-angle-right"></i>
                </a>
              </div>
              <div className="text-container__right">
                <div className="text-container__right__box">
                  <img src={Box1} alt="car-img" />
                  <div className="text-container__right__box__text">
                    <h4>Multi-City Car Rentals</h4>
                    <p>
                      Take your driving experience to the next level with our
                      top-notch vehicles for your cross-country adventures.
                    </p>
                  </div>
                </div>
                <div className="text-container__right__box">
                  {" "}
                  <img src={Box2} alt="coin-img" />
                  <div className="text-container__right__box__text">
                    <h4>All Inclusive Pricing</h4>
                    <p>
                      Get everything you need in one convenient, transparent
                      price with our all-inclusive pricing policy.
                    </p>
                  </div>
                </div>
                <div className="text-container__right__box">
                  {" "}
                  <img src={Box3} alt="coin-img" />
                  <div className="text-container__right__box__text">
                    <h4>Transparent Pricing</h4>
                    <p>
                     Enjoy peace of mind with our commitment to transparent and honest pricing,
                     ensuring no hidden charges.
                    </p>
                  </div>
                </div>
                <div className="text-container__right__box">
                  {" "}
                  <img src={Contact} alt="coin-img" />
                  <div className="text-container__right__box__text">
                    <h4>24/7 Customer Support</h4>
                    <p>
                     Rest assured with our round-the-clock customer support team,
                     ready to assist you whenever you need assistance.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ChooseUs;
