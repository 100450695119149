import { useState } from 'react';
import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";
import emailjs from '@emailjs/browser';
import "../dist/styles.css"

function Contact() {
  const [formStatus, setFormStatus] = useState(null); // State variable for form status
  const [errorMessage, setErrorMessage] = useState(''); // State variable for error message

  const HandleEnquiryForm = (event) => {
    event.preventDefault(); // Prevent default form submission

    const form = event.target; // Get the form element

    // Form validation logic
    if (!form.elements.name.value || !form.elements.email.value || !form.elements.message.value) {
      setFormStatus('failed');
      setErrorMessage('Please fill in all the required fields.');
      return;
    }

    const formData = new FormData();
    formData.append('name', form.elements.name.value);
    formData.append('email', form.elements.email.value);
    formData.append('message', form.elements.message.value);
    const serviceId = 'service_twbha8o';
    const templateId = 'template_h2te7pi';
    const userId = 'vuKFFViyTR9MokUQO';

    // Send form data
    emailjs.sendForm(serviceId, templateId, form, userId)
      .then((response) => {
        console.log('Email sent successfully:', response.status, response.text);
        setFormStatus('success');
        setErrorMessage('');
        form.reset(); // Reset the form after successful submission
        setTimeout(() => setFormStatus(null), 5000000); // Clear the success message after 5 seconds
      })
      .catch((error) => {
        console.error('Email sending failed:', error);
        setFormStatus('failed');
        setErrorMessage('Failed to send email. Please try again later.');
      });
  };

  return (
    <>
      <section className="contact-page">
        <HeroPages name="Contact" />
        <div className="container">
          <div className="responsive-map">
          <iframe title="Google Maps Location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3506.279985675625!2d77.04627747484479!3d28.501222790032713!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1947c63c5be1%3A0x3be9b0ce5f09f30e!2sSainik%20Tour%20and%20Travel!5e0!3m2!1sen!2sin!4v1711247200082!5m2!1sen!2sin" width="800" height="600" allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>
          <div className="contact-div">
            <div className="contact-div__text">
              <h2>Need additional information?</h2>
              <p>
                We provide reliable and convenient car rental services in Gurgaon and its surrounding areas. Whether you need transportation for business or leisure, we have a wide range of vehicles to meet your needs. Contact us today for all your car rental requirements.
              </p>
              <a href="tel:+919910997458">
                <i className="fa-solid fa-phone"></i>&nbsp; (+91)9910997458
              </a>
              <a href="tel:+919818097458">
                <i className="fa-solid fa-phone"></i>&nbsp; (+91)9910997458
              </a>
              <a href="mailto:sainiktour1999@gmail.com">
                <i className="fa-solid fa-envelope"></i>&nbsp; sainiktour1999@gmail.com
              </a>

              <a href="https://www.google.com/maps/place/Gurugram,+Haryana">
                <i className="fa-solid fa-location-dot"></i>&nbsp; Gurugram, Haryana
              </a>
            </div>
            <div className="contact-div__form">
              <form onSubmit={HandleEnquiryForm}>
                <label>
                  Full Name <b>*</b>
                </label>
                <input type="text" name="name" placeholder='E.g: "Joe Shmoe"'></input>

                <label>
                  Email <b>*</b>
                </label>
                <input type="email" name="email" placeholder="youremail@example.com"></input>

                <label>
                  Enquiry <b>*</b>
                </label>
                <textarea name="message" placeholder="Write Here.."></textarea>

                {formStatus === 'success' && <p className="success-message">Message sent successfully!</p>}
                {formStatus === 'failed' && <p className="error-message">{errorMessage}</p>}

                <button type="submit">
                  <i className="fa-solid fa-envelope-open-text"></i>&nbsp; Send
                  Message
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="book-banner">
          <div className="book-banner__overlay"></div>
          <div className="container">
            <div className="text-content">
              <h2>Book a car by getting in touch with us</h2>
              <span>
                <i className="fa-solid fa-phone"></i>
                <h3>(+91) 9910997458</h3>
              </span>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
}

export default Contact;
