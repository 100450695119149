import React from "react";
import Img1 from "../images/download/1.jpeg";
import Img2 from "../images/download/2.jpeg";
import Img3 from "../images/download/3.jpeg";
import Img4 from "../images/download/4.jpeg";
import Img5 from "../images/download/5.jpeg";
import Img6 from "../images/download/6.jpeg";
import Img7 from "../images/download/7.jpeg";
import Img8 from "../images/download/9.jpeg";
import Img9 from "../images/download/Int1.jpeg";
import Img10 from "../images/download/Int2.jpeg";
import Img11 from "../images/download/int3.jpeg";
import Img12 from "../images/download/int4.jpeg";
import '../dist/styles.css'
function Download() {
  return (
    <section className="gallery-section">
      <div className="container">
      <div className="gallery-container">
      <h2 className="gallery-text-custom">Gallery</h2>
        <div className="gallery-text">
          <div className="gallery-images">
            <img alt="download_img" src={Img1} />
            <img alt="download_img" src={Img2} />
            <img alt="download_img" src={Img3} />
            <img alt="download_img" src={Img4} />
            <img alt="download_img" src={Img5} />
            <img alt="download_img" src={Img6} />
            <img alt="download_img" src={Img7} />
            <img alt="download_img" src={Img8} />
            <img alt="download_img" src={Img9} />
            <img alt="download_img" src={Img10} />
            <img alt="download_img" src={Img11} />
            <img alt="download_img" src={Img12} />
          </div>
        </div>
      </div>
      </div>
    </section>
  );
}

export default Download;
