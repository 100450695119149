import React from 'react';
import { FaWhatsapp, FaLinkedin,FaInstagram, FaTwitter,FaFacebook } from 'react-icons/fa';

function Footer() {
  const handleSubscription = () => {
    const email = document.getElementById('emailInput').value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      document.getElementById('errorMessage').innerText = "Please enter a valid email address.";
      document.getElementById('successMessage').innerText = ""; 
      document.getElementById('errorMessage').innerText = "";
      document.getElementById('successMessage').innerText = "Subscription activated successfully!";
    }
  }
  
  return (
    <>
      <footer>
        <div className="container">
          <div className="footer-content">
            <ul className="footer-content__1">
              <li>
                <span>CAR</span> Rental
              </li>
              <li>
                We offers a big range of vehicles for all your driving needs. We
                have the perfect car to meet your needs.
              </li>
              <li>
                <a href="tel:123456789">
                  <i className="fa-solid fa-phone"></i> &nbsp; (+91) 9910997458
                </a>
              </li>

              <li>
                <a
                  href="mailto: 
                carrental@gmail.com"
                >
                  <i className="fa-solid fa-envelope"></i>
                  &nbsp; sainiktour1999@gmail.com
                </a>
              </li>

              {/* <li>
                <a
                  style={{ fontSize: "14px" }}
                  target="_blank"
                  rel="noreferrer"
                  href="https://yogeshdev.com/"
                >
                  Design by Yogesh Singh
                </a>
              </li> */}
            </ul>

            <ul className="footer-content__2">
              <li>Company</li>
              <li>
                <a href="#home">Gurgaon</a>
              </li>
              <li>
                <a href="#home">Careers</a>
              </li>
              <li>
                <a href="#home">Mobile</a>
              </li>
              <li>
                <a href="#home">Blog</a>
              </li>
              <li>
                <a href="#home">How we work</a>
              </li>
            </ul>

            <ul className="footer-content__2">
              <li>Working Hours</li>
              <li>Mon - Fri: 9:00AM - 9:00PM</li>
              <li>Sat: 9:00AM - 19:00PM</li>
              <li>Sun: Closed</li>
            </ul>
            <ul className="footer-content__2">
              <li>Subscription</li>
              <li>
                <p>Subscribe your Email address for latest news & updates.</p>
              </li>
              <li>
                <input type="email" id="emailInput" placeholder="Enter Email Address" />
                <div id="errorMessage" style={{ color: 'red' }}></div> {/* Error message container */}
                <div id="successMessage" style={{ color: 'green' }}></div> {/* Success message container */}
              </li>
              <li>
                <button className="submit-email" onClick={handleSubscription}>Submit</button>
              </li>
            </ul>

          </div>
        </div>
        <div className="footer__container  grid">
          <div className="footer__socials">
            <a href="https://wa.link/p97fx9" target="_blank" rel="noopener noreferrer" className="footer__social-link">
              <FaWhatsapp />
            </a>
            <a href="https://www.linkedin.com/in/devender-yadav-6087132ba" target="_blank" rel="noopener noreferrer" className="footer__social-link">
              <FaLinkedin />
            </a>
            <a href="https://www.instagram.com/sainik_tour_travels/" target="_blank" rel="noopener noreferrer" className="home__social-link footer__social-link">
              <FaInstagram />
            </a>
            <a href="https://twitter.com/Sainik_Travels" target="_blank" rel="noopener noreferrer" className="home__social-link footer__social-link">
              <FaTwitter />
            </a>
            <a href="https://www.facebook.com/Sainiktourandtravel/" target="_blank" rel="noopener noreferrer" className="home__social-link footer__social-link">
              <FaFacebook />
            </a>
          </div>

          <p className="footer__copyright text-cs">
            &copy; 2024-2027 <span>Sainik Tour & Travels</span>. All Rights Reserved
          </p>

          <p className="footer__copyright text-cs"> Developed  &copy; 2024
            <span></span>
          </p>
        </div>
      </footer>
    </>
  );
}

export default Footer;
