import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Img1 from "../images/testimonials/Reviewer1.jpeg";
import Img2 from "../images/testimonials/Reviewer2.jpeg";
import Img3 from "../images/testimonials/Reviewer3.jpeg";
import Img4 from "../images/testimonials/Reviewer4.jpeg";
import Img5 from "../images/testimonials/Reviwer5.jpeg";
import Img6 from "../images/testimonials/Reviwer6.jpeg";
import Img7 from "../images/testimonials/Reviwer7.jpeg";
import Img8 from "../images/testimonials/Reviwer8.jpeg";


function Testimonials() {
  const testimonialsData = [
    {
      name: "Amandeep kaur",
      location: "Paras Hospital, Punjab",
      image: Img7,
      text: "I recently utilized their car rental service for an outstation journey covering Noida to Haridwar and back. I must say, I was thoroughly impressed by the seamless experience. From the professional driver to the well-maintained Innova Crysta, every aspect met my expectations. Absolutely recommended!"
    },
    {
      name: "Rohit Kumar",
      location: "Gurugram, Haryana",
      image: Img2,
      text: "I recently relied on their car rental service for a journey spanning Gurgaon to Noida to Rohtak and back to Gurgaon. I must say, I was thoroughly impressed by the seamless experience. From the professional driver to the well-maintained vehicle, every aspect as per my expectation. Highly recommended!"
    },
    {
      name: "Diyanshi kumari",
      location: "Neemrana, Rajasthan",
      image: Img6,
      text: "Recently, I relied on Book My Car for a journey spanning Neemrana to Mathura to Agra and back. I must say, I was thoroughly impressed by the seamless experience. From the professional driver to the well-maintained vehicle, every aspect was as per my expectation. Highly recommended!"
    },
    {
      name: "Sanju Singh",
      location: "New Delhi",
      image: Img1,
      text: "I recently used their car rental service for an airport drop, and I was impressed by the simplicity and efficiency of the service. The driver was professional and they even provided complimentary water during the ride. Highly recommended for anyone seeking a hassle-free airport pick up and drop off!"
    },
    {
      name: "Ashish Yadav",
      location: "Jaipur, Rajasthan",
      image: Img3,
      text: "My journey from Gurugram to Himachal to Kullu and Haridwar to Gurugram was made seamless with the excellent service provided by Multi-City Car Rentals. The vehicle was well-maintained, and the transparent pricing policy saved me from any unexpected charges. Highly recommended!"
    },
    {
      name: "Navneta Devi",
      location: "Neemrana, Rajasthan",
      image: Img8,
      text: "I recently used their car rental service for an airport drop, and I was impressed by the simplicity and efficiency of the service. The driver was professional and they even provided complimentary water during the ride. Highly recommended for anyone seeking a hassle-free airport pick up and drop off!"
    },
    {
      name: "Sonu Jat",
      location: "New Delhi",
      image: Img5,
      text: "I was truly impressed by their commitment to transparent pricing. As a proponent of financial clarity, I found it reassuring to have a clear understanding of the charges. With no hidden fees, renting a vehicle was straightforward and stress-free. I highly recommend their services!"
    },
    {
      name: "Sandeep Yadav",
      location: "New Delhi",
      image: Img4,
      text: "Exceptional service across the board! From the vehicle quality to their complimentary water provision, GPS assistance, and punctual drivers impeccably dressed, every aspect was top-notch. Their commitment to timekeeping ensured a smooth journey. Highly recommended!"
    },
   
  ];

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <section className="testimonials-section">
      <div className="container">
        <div className="testimonials-content">
          <div className="testimonials-content__title">
            <h4>Reviewed by People</h4>
            <h2>Client's Testimonials</h2>
            <p>Discover the positive impact we've made on our clients by reading through their testimonials. Our clients have experienced our service and results, and they're eager to share their positive experiences with you.</p>
          </div>
          <Slider {...sliderSettings}>
            {testimonialsData.map((testimonial, index) => (
              <div key={index} className="all-testimonials__box">
                <span className="quotes-icon">
                  <i className="fa-solid fa-quote-right"></i>
                </span>
                <p>{testimonial.text}</p>
                <div className="all-testimonials__box__name">
                  <div className="all-testimonials__box__name__profile">
                    <img src={testimonial.image} alt="user_img" />
                    <span>
                      <h4>{testimonial.name}</h4>
                      <p>{testimonial.location}</p>
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
