import React from 'react';
import "../dist/styles.css"

const SectionWithImage = ({ imageSrc, text, heading }) => {
    return (
        <div className='container'>
            <div className='row'>
                <section className="section-image">
                    <div className="image-container">
                        <img src={imageSrc} alt="Illustration for the section" className="image-section" />
                    </div>
                    <div className="text-container-section">
                        <h2>{heading}</h2>
                        <br/>
                        <p>{text}</p>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default SectionWithImage;
