import SelectCar from "../images/plan/icon1.png";
import Contact from "../images/plan/icon2.png";
import Drive from "../images/plan/icon3.png";

function PlanTrip() {
  return (
    <>
      <section className="plan-section">
        <div className="container">
          <div className="plan-container">
            <div className="plan-container__title">
              <h3>Plan your trip now</h3>
              <h2>Quick & easy car rental</h2>
            </div>

            <div className="plan-container__boxes">
              <div className="plan-container__boxes__box">
                <img src={SelectCar} alt="icon_img" />
                <h3>Find Your Ride</h3>
                <p>
                  Discover the perfect ride for your needs on our website. Book via call or WhatsApp.
                </p>
              </div>

              <div className="plan-container__boxes__box">
                <img src={Contact} alt="icon_img" />
                <h3>Instant Assistance</h3>
                <p>
                  Experience seamless, luxurious travel with our real-time concierge support 24/7.
                </p>
              </div>

              <div className="plan-container__boxes__box">
                <img src={Drive} alt="icon_img" />
                <h3>Expert Driver</h3>
                <p>
                Experienced drivers for local and outstation travel. Trust our tailored, reliable service.
                </p>
              </div>

              <div className="plan-container__boxes__box">
                <img src={SelectCar} alt="icon_img" />
                <h3>Vehicle Tracking</h3>
                <p>
                  Intelligent Vehicle tracking systems (IVTS)
                  are used for the purpose of tracking and navigation of vehicles. 
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PlanTrip;
