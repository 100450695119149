import Golf6 from "../images/cars-big/golf6.png";
import AudiA1 from "../images/cars-big/audia1.png";
import Toyota from "../images/cars-big/toyotacamry.jpg";
import Bmw320 from "../images/cars-big/bmw320.png";
import Benz from "../images/cars-big/benz.jpg";
import Passat from "../images/cars-big/passatcc.png";

export const CAR_DATA = [
  [
    {
      name: "Toyota Innova Crysta",
      price: "",
      img: Golf6,
      model: "Innova Crysta",
      mark: "Toyota",
      year: "2023",
      Seater: "7",
      air: "Yes",
      transmission: "Manual",
      fuel: "Diesel",
    },
  ],
  [
    {
      name: "Maruti Suzuki Dzire",
      price: "",
      img: AudiA1,
      model: "Sedan",
      mark: "Maruti",
      year: "2022",
      Seater: "5",
      air: "Yes",
      transmission: "Manual",
      fuel: "Petrol / CNG ",
      GPRS: "Yes"
    },
  ],
  [
    {
      name: "Hyundai Aura",
      price: "",
      img: Toyota,
      model: "Aura",
      mark: "Hyundai",
      year: "2024",
      Seater: "5",
      air: "Yes",
      transmission: "Manual",
      fuel: "Petrol/CNG",
    },
  ],
  [
    {
      name: "Suzuki Ertiga",
      price: "NA",
      img: Bmw320,
      model: "Ertiga Green Vxi",
      mark: "Suzuki",
      year: "2018",
      Seater: "7",
      air: "Yes",
      transmission: "Manual",
      fuel: "Petrol / CNG ",
    },
  ],
  [
    {
      name: "BMW 3 Series",
      price: "NA",
      img: Benz,
      model: "3 Series",
      mark: "BMW",
      year: "2021",
      Seater: "7",
      air: "Yes",
      transmission: "Automatic",
      fuel: "Petrol",
    },
  ],
  [
    {
      name: "Force Motors Tempo Traveller",
      price: "NA",
      img: Passat,
      model: "Tempo Traveller",
      mark: "Force Motors",
      year: "2020",
      Seater: "17",
      air: "Yes",
      transmission: "Manual",
      fuel: "Diesel",
    },
  ],
];
