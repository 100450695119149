import { Link } from "react-router-dom";
import BgShape from "../images/hero/hero-bg.png";
import HeroCar from "../images/hero/car-1.png";
// import { useEffect, useState } from "react";

function Hero() {
  // const [goUp, setGoUp] = useState(false);

  // const scrollToTop = () => {
  //   window.scrollTo({ top: (0, 0), behavior: "smooth" });
  // };

  const bookBtn = () => {
    const phoneNumber = "+919910997458";
    const message = encodeURIComponent("Hello, I'd like to book a ride!");
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${message}`;
    window.open(whatsappLink, "_blank");
  };
  const callBtn = () => {
    const phoneNumber = "+919910997458";
    window.open(`tel:${phoneNumber}`);
};


  // useEffect(() => {
  //   const onPageScroll = () => {
  //     if (window.pageYOffset > 600) {
  //       setGoUp(true);
  //     } else {
  //       setGoUp(false);
  //     }
  //   };
  //   window.addEventListener("scroll", onPageScroll);

  //   return () => {
  //     window.removeEventListener("scroll", onPageScroll);
  //   };
  // }, []);
  return (
    <>
      <section id="home" className="hero-section">
        <div className="container">
          <img className="bg-shape" src={BgShape} alt="bg-shape" />
          <div className="hero-content">
            <div className="hero-content__text">
              <h4>Plan your trip now</h4>
              <h1>
                Save <span>big</span> with our car rental
              </h1>
              <p>
                Rent the car of your dreams. Unbeatable prices, unlimited miles,
                flexible pick-up options and much more.
              </p>
              <div className="hero-content__text__btns">
                <Link
                  onClick={bookBtn}
                  className="hero-content__text__btns__book-ride"
                  to="/"
                >
                  Book Ride &nbsp; <i className="fa-solid fa-circle-check"></i>
                </Link>
                <Link onClick={callBtn} className="hero-content__text__btns__learn-more" to="/">
                  Call Now &nbsp; <i className="fa-solid fa-angle-right"></i>
                </Link>
              </div>
            </div>

            {/* img */}
            <img
              src={HeroCar}
              alt="car-img"
              className="hero-content__car-img"
            />
          </div>
        </div>

        {/* page up */}
        {/* <div
          onClick={scrollToTop}
          className={`scroll-up ${goUp ? "show-scroll" : ""}`}
        >
          <i className="fa-solid fa-angle-up"></i>
        </div> */}
      </section>
    </>
  );
}

export default Hero;
